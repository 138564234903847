<template>
  <div class="skeleton d-flex align-center flex-wrap relative hidden" />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.skeleton {
  flex: 1 1 100%;
  background: #e6e8eb;
  border-radius: 4px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 1.5s infinite;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    transform: translateX(-100%);
    z-index: 1;
  }
  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
